<template>
  <ModalLoginPopup
    v-show="isLoginPopupVisible"
    notify="login"
    classes="s-login-modal"
    @close="$emit('close')"
  >
    <template #modal-content>
      <div class="s-login-modal-container w-full mx-auto flex flex-col md:flex-row">
        <div class="left-container flex flex-col">
          <img
            src="/images/flowery-logo-pink.png"
            alt=""
            class="s-login-icon hidden md:block"
          />
          <ModalAuthForm
            v-if="curForm === 'login'"
            @showform="showForm"
          />
          <ModalResetForm
            v-if="curForm === 'forgot'"
            @showform="showForm"
          />
        </div>

        <div class="right-container hidden md:block">
          <SalveImage
            url="/images/wall-flower-icon.png"
            alt="login or create account"
            loading="lazy"
          />
        </div>
      </div>
    </template>
  </ModalLoginPopup>
</template>

<script>
  export default {
    name: 'LoginModal',

    props: {
      isLoginPopupVisible: Boolean
    },

    data() {
      return {
        curForm: 'login'
      }
    },

    watch: {
      xHasLoginError() {
        if (this.xHasLoginError) {
          this.openedErrorPopup = true
        }
      }
    },

    methods: {
      showForm(form) {
        this.curForm = form
      }
    }
  }
</script>

<style lang="postcss">
  .s-login-modal-error {
    @apply font-alt;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #f3b6d0;
    margin-bottom: 20px;
    @apply p-3;

    .error-text {
      font-size: 12.8px;
      font-weight: 500;
      line-height: 16px;
      color: #000;
    }
  }

  .s-login-modal-container {
    background: #fff;
    box-shadow: 0 2px 34px rgba(0, 0, 0, 0.113435);
    justify-content: space-between;
    @apply font-alt;

    .left-container {
      @apply p-10;

      .s-login-icon {
        height: 34px;
        width: 185px;
      }

      .title {
        font-size: 24px;
        line-height: 36px;
        color: #002d52;
        padding: 10px 0 8px;
      }

      .s-registration-password-svg {
        color: #e5eaed;
        float: right;
        right: 10px;
        top: 32px;
        cursor: pointer;
        display: none;
      }

      .remember-container {
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 12px;
        align-items: center;

        .remember-me {
          margin-top: 0;
          font-size: 10.24px;
          line-height: 13px;
          color: #7c93a5;

          :deep(.s-shop-checkbox + *::before) {
            width: 16px;
            height: 16px;
            vertical-align: center;
          }
        }

        .forgot-password {
          font-size: 10.24px;
          line-height: 13px;
          text-decoration-line: underline;
          color: #4899b9;
          cursor: pointer;
        }
      }

      .login-btn {
        background: #002d52;

        text-align: center;
        align-items: center;
        justify-content: center;
        color: #fff;
        min-width: 300px;

        @apply text-base py-2;

        &.recover {
          margin-top: 19px;

          &-pass {
            margin-top: 23px;
          }
        }
      }

      .login-footer {
        font-size: 12.8px;
        line-height: 16px;
        color: #7c93a5;
        padding-top: 18px;
        border-top: 1px solid #e7ebee;
        margin-top: 26px;

        .create-account-link {
          color: #4899b9;
          cursor: pointer;
        }

        &.recover {
          margin-top: 113px;
        }

        &.rec-pass {
          margin-top: 43px;
        }
      }
    }

    .right-container {
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        min-width: 373px;
      }
    }
  }
</style>
