<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/1 mb-6 md:mb-0">
        <FormValidatedInput
          v-model="form.code"
          name="code"
          placeholder="Password Code"
          :errors="errors"
        />
      </div>

      <div class="w-full lg:w-1/1 mb-6 md:mb-0">
        <FormValidatedInput
          v-model="form.password"
          type="password"
          name="password"
          placeholder="New Password"
          :errors="errors"
        />
      </div>

      <div class="w-full lg:w-1/1">
        <FormValidatedInput
          v-model="form.repeat_password"
          type="password"
          name="repeat"
          placeholder="Confirm Password"
          :errors="errors"
        />
      </div>
    </div>

    <button
      class="login-btn recover-pass flex"
      @click="recoverClicked"
    >
      Recover
    </button>

    <div class="login-footer rec-pass">
      Change your mind?
      <a
        class="underline create-account-link"
        @click="$emit('showform', 'login')"
      >
        Return to Login >
      </a>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ConfirmResetForm',

    props: {
      email: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        errors: {},
        form: {
          code: '',
          password: '',
          repeat_password: ''
        }
      }
    },

    methods: {
      ...mapActions('auth', ['xLogin', 'xConfirmResetPassword']),
      ...mapActions('notifications', ['xAddInfoMessage', 'xAddErrorMessage']),

      async recoverClicked() {
        const response = await this.xConfirmResetPassword({
          email: this.email,
          code: this.form.code,
          password: this.form.password,
          repeat: this.form.repeat_password
        })

        if (response.success) {
          this.xAddInfoMessage({
            message: 'Password Successfully Changed!',
            seconds: 5
          })
          this.$emit('showform', 'login')
        } else {
          this.errors = response.validation.errors.errors
          this.xAddErrorMessage({
            message: response.msg,
            seconds: 10,
            type: 'login'
          })
        }
      }
    }
  }
</script>
