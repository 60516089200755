<template>
  <div>
    <div class="flex flex-row justify-between items-center">
      <div class="title">Login</div>
    </div>

    <div class="flex flex-wrap flex-col md:flex-row">
      <div class="w-full lg:w-1/1 mb-2 md:mb-0">
        <FormValidatedInput
          v-model="credentials.email"
          name="email"
          :errors="errors"
        />
      </div>

      <div class="w-full lg:w-1/1">
        <FormValidatedInput
          v-model="credentials.password"
          type="password"
          name="password"
          :errors="errors"
        />
      </div>
    </div>

    <div class="remember-container flex">
      <ShopPageCheckbox
        :item="{ label: 'Remember Me' }"
        :selected="true"
      />

      <div
        class="forgot-password"
        @click="$emit('showform', 'forgot')"
      >
        Forgot Password
      </div>
    </div>
    <button
      type="button"
      class="login-btn flex w-full"
      @click="loginClicked"
    >
      {{ isLoggingIn ? 'Logging in...' : 'Login' }}
    </button>
    <div class="login-footer">
      Don’t have an account yet?
      <a
        class="underline create-account-link"
        href="/registration"
      >
        Create One >
      </a>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Validator from '@/utils/validation'

  const VALIDATION_RULES = {
    email: 'required|email',
    password: 'required|string'
  }

  export default {
    name: 'AuthForm',

    data() {
      return {
        errors: {},
        rememberMe: false,
        credentials: {
          username: '',
          password: ''
        },
        isLoggingIn: false // Add this line
      }
    },

    computed: {
      ...mapGetters('auth', ['xHasLoginError', 'xLoginErrorMessage'])
    },

    methods: {
      ...mapActions('auth', ['xLogin']),
      ...mapActions('notifications', ['xAddValidationMessage']),

      async loginClicked() {
        this.isLoggingIn = true
        this.errors = {}
        const validation = new Validator(this.credentials, VALIDATION_RULES)
        await this.xLogin({
          username: this.credentials.email,
          password: this.credentials.password
        })
        this.$emit('close')

        if (validation.passes()) {
          console.log('logging in...')
          this.isLoggingIn = true

          await this.xLogin({
            username: this.credentials.email,
            password: this.credentials.password
          }).then(() => {
            this.isLoggingIn = false
          })
          this.$emit('close')
        } else {
          this.errors = validation.errors.errors
          this.isLoggingIn = false
        }
      }
    }
  }
</script>
