<template>
  <ModalConfirm
    v-if="changeModal"
    :handler="changeModal"
  >
    <template #heading> New Store = New Cart</template>
    <template #message>
      Switching to a New Store means You’ll get a NEW CART. Are you cool with emptying your current cart?
    </template>
  </ModalConfirm>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'ChangeModal',
    computed: {
      ...mapState('modals', ['changeModal'])
    },
    watch: {
      changeModal(handler) {
        if (handler) {
          this.xCloseMobileNavbar()
        }
      }
    },
    methods: {
      ...mapActions(['xCloseMobileNavbar'])
    }
  }
</script>
