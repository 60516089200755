<template>
  <div
    class="fixed z-[9999999] inset-0 s-modal-opened-bg bg-opacity-90 transition-opacity"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    @click="close"
  >
    <div
      id="modal-title"
      class="min-h-screen"
    >
      <div class="absolute w-full flex mt-10 md:mt-40 justify-center flex flex-col">
        <div class="w-3/4 xl:w-1/2 mx-auto mt-10 md:mt-0 mb-5">
          <NotificationMessages :type="notify" />
        </div>
        <div
          :class="`mx-auto ` + classes"
          @click.stop
        >
          <slot name="modal-content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LoginPopup',

    props: {
      classes: {
        type: String,
        default: ''
      },

      /**
       * The type of notifications we want to show. Example 'general', 'login'
       */
      notify: {
        type: String,
        default: 'general'
      }
    },

    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .s-modal-opened-bg {
    background: linear-gradient(315deg, rgba(255, 252, 210, 0.9) 0%, rgba(229, 245, 247, 0.9) 49.94%);
    mix-blend-mode: normal;
  }
</style>
