<template>
  <ModalLogin
    :is-login-popup-visible="xShouldShowLoginPopup"
    @close="xCloseLoginPopup"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'XLoginPopup',

    computed: { ...mapGetters('auth', ['xShouldShowLoginPopup']) },
    methods: { ...mapActions('auth', ['xCloseLoginPopup']) }
  }
</script>
