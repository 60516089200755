<template>
  <div>
    <div class="title">Reset Password</div>

    <template v-if="!confirming">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/1 mb-6 md:mb-0">
          <FormValidatedInput
            v-model="form.email"
            name="email"
            placeholder="Email Address"
            :errors="errors"
          />
        </div>
      </div>

      <button
        class="login-btn recover flex"
        @click="sendRecoverEmail"
      >
        Recover
      </button>

      <div class="login-footer recover">
        Change your mind?
        <span
          class="underline create-account-link"
          @click="showForm('login')"
        >
          Return to Login >
        </span>
      </div>
    </template>

    <template v-if="confirming">
      <ModalConfirmResetForm
        :email="form.email"
        @showform="$emit('showform', $event)"
      />
    </template>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Validator from '@/utils/validation'

  const VALIDATION_RULES = {
    email: 'required|email'
  }

  export default {
    name: 'ResetForm',

    data() {
      return {
        errors: {},
        // If confirming is true we have sent an email and we are waiting for the user to enter the
        // provided code
        confirming: false,
        form: {
          email: ''
        }
      }
    },

    methods: {
      ...mapActions('auth', ['xResetPassword']),
      ...mapActions('notifications', ['xAddErrorMessage']),

      showForm(form) {
        this.$emit('showform', form)
      },

      async sendRecoverEmail() {
        this.errors = {}
        const validation = new Validator(this.form, VALIDATION_RULES)
        if (validation.passes()) {
          const response = await this.xResetPassword({ email: this.form.email })

          if (response.success) {
            this.confirming = true
          } else {
            this.xAddErrorMessage({
              message: response.msg,
              seconds: 10,
              type: 'login'
            })
          }

          console.log('rest done', response)
        } else {
          this.errors = validation.errors.errors
        }
      }
    }
  }
</script>
