<template>
  <div
    v-if="show"
    class="fixed inset-0 overflow-y-auto s-modal-opened-bg bg-opacity-40 transition-opacity h-screen flex justify-center md:items-center"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    @click="close"
  >
    <div>
      <div class="flex w-screen h-screen">
        <div class="left-side md:w-1/2 w-full h-full flex justify-center items-center">
          <div
            class="flex flex-col lg:w-[396px] md:w-[345px] w-full gap-[40px] lg:p-0 md:p-12 p-8 md:items-start items-center justify-between mt-12"
          >
            <div>
              <img
                src="/images/logo_white.png"
                class="left-side-logo"
                alt="age"
              />
              <h6 class="text-center md:text-left text-white font-alt text-sm mt-4">Cannabis Delivered</h6>
            </div>
            <p class="font-main lg:font-alt font-bold lg:text-[32px] text-[28px] text-center text-white uppercase">
              Are you over 18?
            </p>
            <div class="left-side-controls flex w-full space-x-4">
              <button
                class="rounded-full lg:w-44 md:w-38 w-[168px] bg-[#EA57A8] hover:bg-[#DA3A93] text-white py-[21px] lg:py-3 px-4"
                @click="handleYes"
              >
                <span>Yes, I am</span>
                <span class="ml-4">&gt;</span>
              </button>
              <button
                class="rounded-full lg:w-44 md:w-38 w-[168px] flex justify-between items-center bg-transparent hover:bg-[#95a7b6] border-2 border-[#EA57A8] text-white py-2 px-4"
                @click="$emit('no')"
              >
                No, I am not
              </button>
            </div>
            <div class="self-start">
              <FormRememberMe
                id="rememberMe"
                :option-label="'Remember me'"
                :input-name="'remember'"
                :is-checked="rememberMe"
                classes="text-xs text-white"
                @update:checked="rememberMe = $event"
              >
                <!-- <template #question> I confirm that this is not a shared device. </template> -->
              </FormRememberMe>
            </div>
            <div class="left-side-terms text-white lg:text-[#7c93a5]">
              By clicking yes and entering the website, I agree to be bound by the <a href="#">Terms of Service</a> and
              <a
                class="underline transition hover:text-pink"
                href="/privacy-policy"
                >Privacy Policy</a
              >.
            </div>
          </div>
        </div>
        <div class="right-side w-1/2 hidden md:block"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        rememberMe: false
      }
    },
    mounted() {
      this.checkStorage()
    },
    methods: {
      handleYes() {
        this.$emit('yes')
        if (this.rememberMe) {
          const now = new Date()
          const expiryDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000) // 30 days from now
          localStorage.setItem('modalDisabledUntil', expiryDate.toISOString())
        }
      },
      checkStorage() {
        const disabledUntil = new Date(localStorage.getItem('modalDisabledUntil'))
        if (disabledUntil > new Date()) {
          this.$emit('yes')
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-modal-opened-bg {
    background-color: rgba(0, 45, 82, var(--tw-bg-opacity));
    z-index: 1001;
    overflow: hidden;
  }

  .left-side {
    font-family: DM Mono, serif;
    background: #161616;

    @media screen and (max-width: 767px) {
      background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)),
        url(/images/modal-age-background.webp) no-repeat;
      background-size: cover !important;
    }

    .left-side-logo {
      width: 324px;
    }

    .left-side-terms {
      font-size: 12px;
      line-height: 18px;
      padding-bottom: 33px;

      @media screen and (max-width: 640px) {
        margin-bottom: 33px;
      }
    }
  }

  .right-side {
    background: url(/images/modal-age-background.jpg);
    background-size: 100%;
    position: relative;
  }
</style>
